:root {
  --dark1: #055370;
  --dark2: #056d79;
  --light1: #9dfbf5;
  --light2: #9cfcee;
  --xl-light1: white;
  --xl-light2: #c8fffa;
}



body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
