#header {
    display: flex;
    min-height: 100vh;
    width: 100%;
    padding: auto;
    align-items: center;
    text-align:center;
    color: white;
    background-size: cover;

    background-color: rgba(0,0,0,.7);
    background-blend-mode: multiply;
}

#header > div {
    margin:auto
}


#header-text {
    font-size: 5rem;
    margin: 0px;
}


#subheader-text {
    font-size: 2rem;
    margin: 0px;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
}


#header > div > h3 {
    margin: 20px 0 0 0;
    font-size: 1rem;
}

#contact-btn {
    margin: 8vh;
}



#product {
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 100vh;
    margin: auto;
    /* background-image: linear-gradient(to bottom, #111, #555); */
    background-color: #333;
    color: white;
}

#product > .content-card {
    display: block;
    width: 100%;
    grid-gap: 40px;
}

.right-card {
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    margin: 30px;
}

.right-card > h1 {
    margin: 0px 0px 40px 0px;
    font-size: 1.6em;
    font-weight: 900;
}
.right-card > h2 {
    margin: 0px 0px 20px 0px;
    font-size: 1.2em;
}
.right-card > text {
    margin: 0px 0px 20px 0px;
    font-size: 0.8em;
}
.right-card > .price {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding : 40px;
    align-items: center;
    justify-content: space-evenly;
}

.right-card > .price > h2 {
    font-size: 2.5rem;
    text-align: center;
    margin: 30px;
}

.order-btn > .btn, .btn-lg{
    font-size: 1.5rem;
}

.left-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-shrink: 0;
    width: 100%;
    padding: 5px;
}

.left-card > img {
    align-self: center;
    max-height: 80vh ;
    max-width: calc( 100% - 30px );
}



#contact-us {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    min-height: calc( 100vh - 100px );
    color: white;
}

/* #contact-us > h3{
    font-size: 2.5rem;
    text-align: center;
    width: 100%;
    margin: auto 0 50px 0;
} */
#contact-info {
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr;
    vertical-align: middle;
    margin: auto auto auto auto;
}

#contact-info > h2 {
    text-align: left;
    padding-bottom: 20px;;
    font-size: 3.5rem;
}
#contact-info > a {
    color: white !important;
    text-decoration: underline !important;
    text-decoration-color: white;  
    text-align: left;
    margin: 0px 0px 15px 0px;
    font-size: 1.5rem;
}
#contact-info > h3 {
    text-align: left;
    margin: 0px 0px 0px 0px;
    font-size: 1.5rem;
}

#contact-bg-div {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000;

}

#contact-bg-img {
    object-fit: cover;
    object-position: center;
    width: 100vw;
    height: 100vh;
    min-width: 800px;
    opacity: 0.5;
    /* max-height: 100vh; */
    
}


@media only screen and (min-width: 400px) {
    .left-card {
        padding: 20px;
    }
    
    .left-card > img {
        /* align-self: center;
        max-height: 80vh ; */
        max-width: calc( 100% - 30px );
    }

    #product > .content-card {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 800px) {
    #header-text {
        font-size: 8rem;
    }
    #subheader-text {
        font-size: 3rem;
    }

    #header > div > h3 {
        font-size: 1.5rem;
    }

    #contact-us {
        grid-template-columns: 1fr minmax(500px, 1fr);

    }
    #contact-info {
        grid-column: 2;
        margin: auto auto auto 50px;
        grid-template-columns: auto auto;
        transform: translate(0px, -25%);
        
    }
    #contact-info > h2 {
        grid-area: 1 / 1 / 2 / 3;
        padding-bottom: 30px;;
        font-size: 5rem;
    }
    #contact-info > a {
        margin: auto 0 auto 10px;
    }
    #contact-info > h3 {
        margin: 5px 10px 5px 0;
    }
}

@media only screen and (min-width: 1100px) {
    #header-text {
        font-size: 9rem;
    }
    #product > .content-card {
        grid-template-columns: minmax(30%, auto) minmax(60%, 1fr);
    }
}


#footer {
    display: flex;
    height: 100px;
    width: 100%;
    background-color: #00000088;
}

#footer >p {

    text-align: center;
    vertical-align:middle;
    text-align: center;
    width: 100%;
    margin: auto;
    color: white;
}